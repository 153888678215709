import { createContext, useReducer, useContext, Dispatch } from 'react';

import { SortType } from '@models';
import { sortData } from '@utils';

import {
  CREATE_NEW_ROW,
  DROP_FILE,
  SET_EDITABLE_ROW_INDEX,
  SET_ERROR,
  SET_LOADING,
  SET_REPLACE_REQUESTED,
  SET_UPLOAD_DATA,
  SET_MANUAL_INPUT_ENABLED,
  SHOW_REPLACE_DIALOG,
  DELETE_ROW,
  CREATED_CORPORATE_ID,
  SORT_DATA,
  SET_MULTIPLE_UPLOAD,
  RESET_DATA,
  SET_COLUMN_ERRORS,
  DISABLE_DOWNLOAD_SAVED_DATA,
  SET_READ_ONLY_MODE,
  SET_EDITABLE_FLAG,
  SET_ACTIVE_UPLOAD_FLOW,
} from './actions';

export type State = {
  uploadedData: any;
  manualInputEnabled: boolean;
  error: {
    title: string;
    message: string[] | string;
  };
  loading: boolean;
  replaceRequested: boolean;
  showReplaceDialog: boolean;
  editableRowIndex: number;
  createdCorporateId: string | null;
  multipleUpload: boolean;
  sorting: {
    dir: SortType;
    key: string;
  };
  columnErrors: { [key: string]: string };
  untouchedUploadData: any;
  disableDownloadSavedData: boolean;
  readOnlyMode: boolean;
  isEditable: boolean;
  activeUploadFlow: { assetType: string; step: number } | null;
};

type Action =
  | {
      type: typeof SET_UPLOAD_DATA;
      value: any;
      storeInitData: boolean;
    }
  | {
      type: typeof SET_MANUAL_INPUT_ENABLED;
      value: boolean;
    }
  | {
      type: typeof SET_ERROR;
      title: string;
      message: string[] | string;
    }
  | {
      type: typeof SET_LOADING;
      value: boolean;
    }
  | {
      type: typeof SET_REPLACE_REQUESTED;
      value: boolean;
    }
  | {
      type: typeof SHOW_REPLACE_DIALOG;
      value: boolean;
    }
  | {
      type: typeof SET_EDITABLE_ROW_INDEX;
      value: number;
    }
  | {
      type: typeof CREATE_NEW_ROW;
    }
  | {
      type: typeof DROP_FILE;
      value: any;
    }
  | {
      type: typeof DELETE_ROW;
      index: number;
    }
  | {
      type: typeof CREATED_CORPORATE_ID;
      id: string;
    }
  | { type: typeof SORT_DATA; sorting: { key: any; dir: SortType }; fieldType: string }
  | { type: typeof SET_MULTIPLE_UPLOAD; value: boolean }
  | { type: typeof RESET_DATA }
  | { type: typeof SET_COLUMN_ERRORS; value: any }
  | { type: typeof DISABLE_DOWNLOAD_SAVED_DATA; value: boolean }
  | { type: typeof SET_EDITABLE_FLAG; value: boolean }
  | { type: typeof SET_READ_ONLY_MODE; value: boolean }
  | { type: typeof SET_ACTIVE_UPLOAD_FLOW; value: { assetType: string; step: number } | null };

const initialState: State = {
  uploadedData: { data: [], header: [], selectsIndexes: [] },
  manualInputEnabled: true,
  error: {
    title: '',
    message: '',
  },
  loading: false,
  replaceRequested: false,
  showReplaceDialog: false,
  editableRowIndex: 0,
  createdCorporateId: null,
  multipleUpload: false,
  sorting: {
    dir: 'desc',
    key: '',
  },
  columnErrors: {},
  untouchedUploadData: [],
  disableDownloadSavedData: true,
  readOnlyMode: false,
  isEditable: true,
  activeUploadFlow: null,
};

const UploadPreviewReducer = createContext<{
  state: State;
  dispatch: Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case SET_UPLOAD_DATA: {
      return {
        ...state,
        uploadedData: action.value,
        ...(action.storeInitData && { untouchedUploadData: action.value.data }),
      };
    }
    case SET_MANUAL_INPUT_ENABLED: {
      return {
        ...state,
        manualInputEnabled: action.value,
      };
    }
    case SET_ERROR: {
      return {
        ...state,
        error: {
          title: action.title,
          message: action.message,
        },
      };
    }
    case SET_LOADING: {
      return {
        ...state,
        loading: action.value,
      };
    }
    case SET_REPLACE_REQUESTED: {
      return {
        ...state,
        replaceRequested: action.value,
      };
    }
    case SHOW_REPLACE_DIALOG: {
      return {
        ...state,
        showReplaceDialog: action.value,
      };
    }
    case SET_EDITABLE_ROW_INDEX: {
      return {
        ...state,
        editableRowIndex: action.value,
      };
    }
    case CREATE_NEW_ROW: {
      return {
        ...state,
        uploadedData: {
          ...state.uploadedData,
          data: [...state.uploadedData.data, Array(state.uploadedData.header.length).fill('')],
        },
        editableRowIndex: state.uploadedData.data.length,
      };
    }
    case DROP_FILE: {
      const { data, header, rows } = action.value;
      const delimiterIndex = data.findIndex((item: string[]) => !item.length);
      const tail = data.slice(delimiterIndex + 1);
      const head = data
        .slice(0, delimiterIndex !== -1 ? delimiterIndex : data.length)
        .map((item, index) => [index + 2, ...item]);
      const tailItems = tail.map((item, index) => [rows - tail.length + 1 + index, ...item]);
      const multipleUploadData = delimiterIndex !== -1 ? [...head, ['...'], ...tailItems] : head;
      const idIndex = header.findIndex((item: string) => item === 'id');
      const singleUploadData =
        idIndex > -1
          ? data.map((item) => item.filter((_, index: number) => index !== idIndex))
          : data;

      return {
        ...state,
        uploadedData: {
          ...state.uploadedData,
          data: state.multipleUpload ? multipleUploadData : singleUploadData,
          header: state.multipleUpload
            ? ['head-number', ...header]
            : header.filter((item: string) => item !== 'id'),
        },
        manualInputEnabled: false,
        editableRowIndex: -1,
        columnErrors: {},
      };
    }
    case DELETE_ROW: {
      const newData = [...state.uploadedData.data];
      newData.splice(action.index, 1);
      return {
        ...state,
        uploadedData: { ...state.uploadedData, data: newData },
        editableRowIndex: -1,
      };
    }
    case CREATED_CORPORATE_ID: {
      return {
        ...state,
        createdCorporateId: action.id,
      };
    }
    case SORT_DATA: {
      const modifiedDataWithKeys = state.uploadedData.data.map((row: string[]) => {
        const rowData = {};
        row.forEach((cell, cellIndex) => {
          const currentHeader = state.uploadedData.header[cellIndex];
          rowData[currentHeader] = cell;
        });

        return rowData;
      });
      const data = sortData({
        data: modifiedDataWithKeys,
        sorting: action.sorting,
        type: action.fieldType,
      });

      return {
        ...state,
        uploadedData: { ...state.uploadedData, data: data.map((item) => Object.values(item)) },
        sorting: action.sorting,
      };
    }
    case SET_MULTIPLE_UPLOAD: {
      return {
        ...state,
        multipleUpload: action.value,
        manualInputEnabled: !action.value,
      };
    }
    case RESET_DATA: {
      return {
        ...state,
        uploadedData: { data: [], header: [], selectsIndexes: [] },
        columnErrors: {},
      };
    }
    case SET_COLUMN_ERRORS: {
      return {
        ...state,
        columnErrors: action.value,
      };
    }
    case DISABLE_DOWNLOAD_SAVED_DATA: {
      return {
        ...state,
        disableDownloadSavedData: action.value,
      };
    }
    case SET_READ_ONLY_MODE: {
      return {
        ...state,
        readOnlyMode: action.value,
      };
    }
    case SET_EDITABLE_FLAG: {
      return {
        ...state,
        isEditable: action.value,
      };
    }
    case SET_ACTIVE_UPLOAD_FLOW: {
      return {
        ...state,
        activeUploadFlow: action.value
          ? {
              assetType: action.value?.assetType,
              step: action.value?.step,
            }
          : null,
      };
    }
    default:
      return state;
  }
};

const UploadPreviewProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <UploadPreviewReducer.Provider value={{ state, dispatch }}>
      {children}
    </UploadPreviewReducer.Provider>
  );
};

const useUploadPreviewContext = () => {
  return useContext(UploadPreviewReducer);
};

export { UploadPreviewProvider, useUploadPreviewContext };
